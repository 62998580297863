import React from "react";
import Layout from "../components/Layout";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { options } from "../styles/TypographySettings";
import Paper from "@material-ui/core/Paper";
import { connect } from 'react-redux';
import { setAssets } from "../state/actions/components";

class BlogEntry extends React.Component<any, any> {
  componentWillMount() {
    this.props.setAssets(this.props.pageContext.assets);
  }
  render() {
    const context = this.props.pageContext;
    const _copy: any = JSON.parse(
      context.childContentfulBlogEntryCopyRichTextNode.copy
    );
    const copy = documentToReactComponents(_copy, options);
    const {
      createdAt,
      id,
      pageTitle,
      showTitle,
      searchIndex,
      metaDescription
    } = context;
    const SEO = { searchIndex, metaDescription };
    const content = (
      <Paper className="page">
        <Grid container className="page__content">
          <Grid item className="page__col">
            <div className="page__section">
              <Typography variant="h4">{pageTitle}</Typography>
              <Typography variant="title">
                Posted {new Date(`${createdAt}`).toLocaleDateString()}
              </Typography>
              {copy}
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
    return (
      <Layout
        components={[
          {
            columns: 12,
            content,
            className: "blog-entry"
          }
        ]}
        authRequired={false}
        seo={SEO}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  assets: state.components.assets
});

export default connect(mapStateToProps, { setAssets })(BlogEntry);
